import React, { useState, useEffect } from "react";
import './css/Sidepanel.css';
import { backendUrl } from "../dataset/AdminData";
import { Link, useLocation } from "react-router-dom";

const Sidepanel = () =>{
    const [pathname, setPathname] = useState('');
    const location = useLocation();
    const [sidePanelHeight, setSidePanelHeight] = useState(0);

  useEffect(() => {
    // Function to update side panel height
    const updateSidePanelHeight = () => {
      const bodyHeight = document.body.clientHeight;
      setSidePanelHeight(bodyHeight);
      // console.log(bodyHeight);
    };

    // Initial update
    updateSidePanelHeight();

    // Update side panel height on window resize
    window.addEventListener('resize', updateSidePanelHeight);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', updateSidePanelHeight);
    };
  }, []);

    useEffect(() => {
        setPathname(location.pathname);
      }, [location.pathname]); 

    const handleLogout = async () => {
        try {
          let link = backendUrl + 'logout.php'
          let response = await fetch(link, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                // Include any additional headers as needed
              },
              // Optionally, include any authentication credentials or tokens here
              credentials: 'include', // Add this line to include credentials
            });
          let data = await response.json();
          if (response.ok) {
            // Logout successful, redirect to home page
            window.location.href = '/';
    
          } else {
            console.error('Logout failed:', data);
          }
        } catch (error) {
          console.error('An error occurred during logout:', error);
        }
      };

      
    return(
        <div style={{backgroundColor: 'var(--secondary-background)', minHeight: '100dvh', height: {sidePanelHeight}, padding: '20px 20px', boxSizing: 'border-box', position: 'relative'}}>
            <div>
                <div className="logo" style={{marginTop: '15px'}}>
                    <img src="/logo.png" alt="Octtoppus Logo" style={{width: '150px'}} />
                </div>
                <nav className="navbar" style={{marginTop: '50px', height: {sidePanelHeight}, boxSizing: 'border-box'}}>
                    <Link className={`navbar-links  ${pathname === '/dashboard' ? 'active' : ''}`} to="/dashboard">Dashboard</Link>
                    <Link className={`navbar-links  ${pathname === '/user' ? 'active' : ''}`} to="/user">User</Link>
                    <div className="navbar-button" onClick={handleLogout}>Log-out</div>
                </nav>
            </div>

        </div>
    );
    
}

export default Sidepanel;