import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../dataset/AdminData';
import { UserProvider } from '../UserContext';

const AuthenticatedPage = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const link = backendUrl + 'auth_login.php';
        const response = await fetch(link, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            // Include any additional headers as needed
          },
          // Optionally, include any authentication credentials or tokens here
          credentials: 'include', // Add this line to include credentials
        });
        if (response.ok) {
          const data = await response.json();
          setUserData(data.user);
          setIsAuthenticated(true);
        } else if (response.status === 403) {
          setIsAuthenticated(false);
          navigate('/');
        }
      } catch (error) {
        console.error('An error occurred while checking authentication:', error);
        setIsAuthenticated(false)
      }
    };

    checkAuthentication();
  }, [navigate]);

  if (!isAuthenticated) {
    // If user is not authenticated, deny access and redirect to login page
    return null; // Render nothing
  }

  // If user is authenticated, render the children (page content) within the UserProvider
  return (
    <UserProvider userData={userData}>
      <div>{children}</div>
    </UserProvider>
  );
};

export default AuthenticatedPage;
