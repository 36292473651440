// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import './App.css';
import MainLayout from './MainLayout';
import { UserProvider } from './UserContext';

const Login = lazy(() => import('./Pages/Login'));
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const AddBlog = lazy(() => import('./Pages/AddBlog'));
const NotFound = lazy(() => import('./Pages/NotFound'));
const User = lazy(() => import('./Pages/User'));
const Edit = lazy(() => import('./Pages/Edit'));
const AddAdmin = lazy(() => import('./Pages/AddAdmin'));

const App = () => {
  return (
      <Router>
      <Suspense fallback={<MainLayout><div>Loading...</div></MainLayout>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<MainLayout><Dashboard /></MainLayout>} />
          <Route path="/add-blog" element={<MainLayout><AddBlog /></MainLayout>} />
          <Route path="/add-admin" element={<MainLayout><AddAdmin /></MainLayout>} />
          <Route path="/user" element={<MainLayout><User /></MainLayout>} />
          <Route path="/edit/:blogid" element={<MainLayout><Edit/></MainLayout>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
