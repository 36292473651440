import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../UserContext";

const TopBar  = () =>{
    const [pathname, setPathname] = useState('');
    const location = useLocation();
    const { firstName, lastName } = useContext(UserContext);

    useEffect(() => {
        switch(location.pathname){
            case '/dashboard':
                setPathname('Dashboard');
                break;
            case '/user':
                setPathname('User');
                break;
            case '/add-blog':
                setPathname('New Blog');
                break;
            case '/edit':
                setPathname('Edit Blog');
                break;
            default:
                break;
        }
      }, [location.pathname]); 
    

    return(

        <div style={{backgroundColor: 'var(--secondary-background)', height: '100px', width: '100%'}}>
            <div style={{height: '100%', display: 'flex', alignItems: 'center', padding: '0px 50px'}}>
                <h1 style={{color: 'var(--text-color-primary)'}}>
                    {pathname}
                </h1>
                <h3 style={{color: 'var(--text-color-primary)', marginLeft: 'auto'}}>
                    Welcome, {firstName} {lastName}
                </h3>
                
            </div>

        </div>
    );

}

export default TopBar;