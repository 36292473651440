import React from "react";
import AuthenticatedPage from "./Components/AuthenticatedPage";
import Sidepanel from "./Components/Sidepanel";
import TopBar from "./Components/TopBar";

const MainLayout = ({children})=>{
    

    return(
        <AuthenticatedPage>
            <div className="main-layout" style={{display: 'flex'}}>
                <Sidepanel />
                <div className="content" style={{width: '100%'}}>
                    <TopBar />
                    {children}
                </div>

            </div>
        </AuthenticatedPage>
    );


}

export default MainLayout;