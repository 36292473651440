import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children, userData }) => {
    const [firstName, setFirstName] = useState(userData ? userData.first_name : '');
    const [lastName, setLastName] = useState(userData ? userData.last_name : '');
    const [adminId, setAdminId] = useState(userData ? userData.admin_id : 0);
    const [owner, setOwner] = useState(userData ? userData.owner : 0);
    return (
        <UserContext.Provider value={{ firstName, setFirstName, lastName, setLastName, adminId, setAdminId, owner, setOwner }}>
            {children}
        </UserContext.Provider>
    );
};
